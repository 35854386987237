import { Accordion, Badge, Button, Grid, Group, LoadingOverlay, Text, rem } from "@mantine/core";
import { useDatabse } from "../contexts/DatabaseContex"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { IconStar } from "@tabler/icons-react";

export default function EventInfoModal({ context, id, innerProps: eventData }) {
    const {isLoading, getEventById} = useDatabse();
    const [searchParams] = useSearchParams();
    const [event, setEvent] = useState();

    useEffect(() => {
        const getEvent = async () => {
            if (!searchParams.get("eventId")) {
                return
            }
            try {
                const response = await getEventById(searchParams.get("eventId"));
                setEvent(response);
            } catch (e) {
                return
            }
        }
        getEvent();
    }, []);

    return (
        <div className="relative">
            <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{radius: "sm", blur: 2}} />
            {event ? (
                <>
                <Grid>
                <Grid.Col span={6}>
                    <Text fw={500}>Místo</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{event.place}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Začátek</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{!event.unknowStart ? format(event.startDatetime.toDate(), "dd. M. yyyy HH:mm") : format(event.startDatetime.toDate(), "dd. M. yyyy") + " - Neznámy"}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Konec</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{!event.unknowEnd ? format(event.endDatetime.toDate(), "dd. M. yyyy HH:mm") : format(event.endDatetime.toDate(), "dd. M. yyyy") + " - Neznámy"}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Typ akce</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{event.type === 0 ? "Veřejná akce" : event.type === 1 ? "Soukromá akce" : "Otazníková akce"}</Text>
                </Grid.Col>
                <>
                <Grid.Col span={12}>
                    <Accordion>
                        <Accordion.Item value="hvezdy">
                            <Accordion.Control 
                                icon={<IconStar style={{ width: rem(20), height: rem(20) }} />}
                            >
                                Hvězdy večera
                            </Accordion.Control>
                            <Accordion.Panel>
                            {Object.keys(event.attendees).map((key, index) => (
                                <>
                                <Grid>
                                    <Grid.Col key={index} span={6}>
                                        <Text>{event.attendees[key].name}</Text>
                                    </Grid.Col>

                                    <Grid.Col key={index + "_51"} span={6}>
                                        <Badge color={event.attendees[key].confirmed === 0 ? "blue" : event.attendees[key].confirmed === 1 ? "green" : "red"}>
                                            {event.attendees[key].confirmed === 0 ? "Čeká se.." : event.attendees[key].confirmed === 1 ? "Potvrzeno" : "Nepotvrzeno"}
                                        </Badge>
                                    </Grid.Col>
                                </Grid>
                                </>
                            ))}
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </Grid.Col>
                
                </>
            </Grid>
                <Group justify="space-between" mt="md">
                    <Button color="red" onClick={() => {}}>Nemám čas</Button>
                    <Button color="green" onClick={() => {}}>Potvrdit</Button>
                </Group>
            </>
            ) : (
                <Text>Asi se akce smazala :(</Text>
            )}
        </div>
    )
}