import { Button, Flex, Grid, Image, Paper, Text, Title } from '@mantine/core';
import React, { useRef, useTransition, useEffect } from 'react';
import '@mantine/carousel/styles.css';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { Calendar } from '../components/Calendar';
import ClosestEvent from '../components/ClosestEvent';
import { Link, useSearchParams } from 'react-router-dom';
import ContactUs from '../components/ContactUs';
import { modals } from '@mantine/modals';
import { useDocumentTitle } from '@mantine/hooks';

function Home() {
    const autoplay = useRef(Autoplay({ delay: 2500 }));
    const [isPending, startTransition] = useTransition();
    const [searchParams] = useSearchParams();

    useDocumentTitle('Cimbálová Muzika - Kuráž');

    useEffect(() => {
        if (searchParams.get("eventId") !== null) {
            startTransition(() => {
                modals.openContextModal({
                    modal: 'eventInfo',
                    title: 'Info'
                });
            });
        }
    }, [searchParams]);

    const images = [
        {
            alt: 'cimbálová-muzika-kuráž-liptovská-teplička',
            src: 'liptovskat.webp',
            placeholder: 'liptovskat-placeholder.webp',
        },
        {
            alt: 'cimbálová-muzika-kuráž-ratiboř1',
            src: 'photo1.webp',
            placeholder: 'photo1-placeholder.webp',
        },
        {
            alt: 'cimbálová-muzika-kuráž-ratiboř2',
            src: 'photo2.webp',
            placeholder: 'photo2-placeholder.webp',
        },
        {
            alt: 'cimbálová-muzika-kuráž-hošťálková',
            src: 'photo3.webp',
            placeholder: 'photo3-placeholder.webp',
        }
    ];

    return (
        <div className='mt-3'>
            <Paper withBorder p="xs" className='drop-shadow'>
                <Grid>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Carousel
                            withIndicators
                            height={400}
                            plugins={[autoplay.current]}
                            onMouseEnter={autoplay.current.stop}
                            onMouseLeave={autoplay.current.reset}
                        >
                            {images.map((image, index) => (
                                <Carousel.Slide key={index}>
                                    <Image src={image.src}
                                        className='h-full w-full object-cover'
                                    />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Title order={1}>Cimbálová muzika Kuráž</Title>
                        <Text mt="sm">Cimbálová muzika Kuráž z Liptálu vznikla v roce 2012 pod ZUŠ Morava. Jsme skupina mladých hudebních nadšenců, kteří mají hlubokou vášeň pro tradiční lidovou hudbu a snažíme se ji předávat nové generaci posluchačů. Naše hudba je oslavou kulturního dědictví a nabízí autentický zážitek z valašské, slovácké i slovenské hudby.</Text>
                        <Text>Vyznačujeme se nejen výjimečným hudebním talentem, ale také neuvěřitelnou energií a schopností přenést pozitivní náladu na naše publikum. Každé naše vystoupení je jedinečné a plné živé hudby, která osloví každého, kdo má rád tradiční melodie a taneční rytmy, ale i moderní skladby.</Text><br />
                        <Text>Hledáte autentický hudební zážitek? Přijďte si nás užít na našem příštím vystoupení. Sledujte naše stránky a sociální sítě pro aktuální informace o nadcházejících koncertech a událostech. Spojte se s námi a staňte se součástí naší hudební rodiny!</Text>
                    </Grid.Col>
                </Grid>
            </Paper>

            <Grid>
                <Grid.Col span={{ base: 12, md: 6 }}>
                    <Paper shadow='xs' p="xs" withBorder className='mt-5'>
                        <ClosestEvent />
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                    <Paper shadow='xs' p="xs" withBorder className='md:mt-5 mt-2'>
                        <Flex
                            mih={50}
                            gap={8}
                            justify="center"
                            align="flex-start"
                            direction="column"
                            wrap="wrap"
                        >
                            <Title order={4}>Máte zájem?</Title>
                            <Text>Naše cimbálová muzika Kuráž přináší autentický zážitek z lidové hudby. Ať už plánujete svatbu, rodinnou oslavu, firemní večírek, nebo jakoukoliv jinou společenskou událost, jsme tu, abychom přidali ten správný hudební doprovod.</Text>
                            <Link to="/order"><Button mt={{ base: 5, md: 28, lg: 53 }}>Nezávazně objednat</Button></Link>
                        </Flex>
                    </Paper>
                </Grid.Col>
            </Grid>
            <Paper shadow='xs' p="xs" withBorder className='mt-5'>
                <div className=" max-w-screen px-0 sm:px-0 mx-auto md:px-0">
                    <div className="md:grid md:grid-cols-2 pt-3 md:p-5 md:divide-x md:divide-gray-200">
                        <Calendar />
                    </div>
                </div>
            </Paper>
            <Paper shadow="xs" withBorder className='mt-5 !p-0' id='contact'>
                <ContactUs />
            </Paper>
        </div>
    );
}

export default Home;
