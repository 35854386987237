import { createContext, useContext, useState, useMemo, useCallback, useRef } from "react";
import { Timestamp, addDoc, collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../firebase-config";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import { API, EVENT_COLORS } from "../constants/API";
import { addDays, endOfMonth, format, startOfMonth } from "date-fns";
import { useAuth } from "./AuthContext";

const DatabaseContext = createContext();

export function useDatabse() {
    return useContext(DatabaseContext);
}

const parseMonthYearToDate = (monthYear) => {
    const months = {
        January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
        July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
    };

    const [monthName, year] = monthYear.split('-'); // Now expects the year to be four digits
    const month = months[monthName];
    if (month === undefined || !year) {
        throw new Error("Invalid month-year format");
    }
    return new Date(parseInt(year, 10), month, 1); // Returns the first day of the given month and year
};

export function DatabseProvider({ children }) {
    const { user, isAdmin } = useAuth();
    const eventRef = collection(firestore, "Events");
    const [isLoading, setIsLoading] = useState(false);
    const eventCache = useRef({});

    const eventTypes = useMemo(() => (isAdmin ? [0, 1, 2] : [0, 1]), [isAdmin]);

    const getEventsForMonth = useCallback(async (currentMonth = "April-2024") => {
        const date = parseMonthYearToDate(currentMonth);
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);

        if (eventCache.current[currentMonth]) {
            return eventCache.current[currentMonth];
        }

        try {
            const q = query(
                eventRef,
                where("startDatetime", ">=", Timestamp.fromDate(monthStart)),
                where("startDatetime", "<=", Timestamp.fromDate(monthEnd)),
                where("type", "in", eventTypes)
            );
            const response = await getDocs(q);
            const events = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            eventCache.current[currentMonth] = events;
            return events;
        } catch (error) {
            console.error("Error fetching events:", error);
            return [];
        }
    }, [eventTypes, eventRef]);

    const getClosestEvent = useCallback(async () => {
        const now = new Date();

        try {
            const q = query(
                eventRef,
                where("startDatetime", ">=", Timestamp.fromDate(now)),
                where("type", "in", eventTypes),
                orderBy("startDatetime", "asc"),
                limit(2)
            );

            const response = await getDocs(q);
            const event = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            return event.length > 0 ? event : null;
        } catch (error) {
            console.error("Error fetching the closest event:", error);
            return null;
        }
    }, [eventTypes, eventRef]);


    const createOrderEvent = useCallback(async (description, startDatetime, endDatetime, place, name, email, info, phoneNum, price) => {
        setIsLoading(true);
        try {
            const formData = {
                title: "Objednávka - " + name,
                name,
                description,
                startDatetime: Timestamp.fromDate(new Date(startDatetime)),
                endDatetime: Timestamp.fromDate(new Date(endDatetime)),
                unknowStart: false,
                unknowEnd: false,
                type: 2,
                info,
                phoneNum,
                price,
                order: 1,
                user: email,
                confirmed: 0,
                place,
                color: EVENT_COLORS[2],
                createdAt: Timestamp.fromDate(new Date()),
                attendees: {
                    "shwt3Ni8VOU149nZUGrLS8TvhSl1": {
                        name: "Piňďa",
                        confirmed: 0
                    },
                    "8mPgPiO83Fdr5g7ufRyKfKs7PLv1": {
                        name: "Tom",
                        confirmed: 0
                    },
                    "b3ZXkzdeqlPYMGYZ5LL2IdveRmI2": {
                        name: "Helča",
                        confirmed: 0
                    },
                    "lYw8fX74jnVedqFpszTOTQXksXc2": {
                        name: "Evička",
                        confirmed: 0
                    },
                    "5oNg3J5qM0gqB60gDGisgtl8tA82": {
                        name: "Věruška",
                        confirmed: 0
                    },
                    "jcQtVKCUO4NOZFXEiAizRgWaR0N2": {
                        name: "Pája",
                        confirmed: 0
                    },
                    "SWsYGxj8IUXEIxFZOm6yXFw4zT02": {
                        name: "Peťko",
                        confirmed: 0
                    },
                    "4xAfwDvhdscrjcKVGt3ajETlybC2": {
                        name: "Pepík",
                        confirmed: 0
                    }
                }
            };

            const docRef = await addDoc(eventRef, formData);
            const eventId = docRef.id;

            const response = await fetch(`${API}emails/send-order-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: eventId,
                    title: `${name}`,
                    place,
                    name,
                    startDatetime: format(startDatetime, "d. M. yyyy HH:mm"),
                    endDatetime: format(endDatetime, "d. M. yyyy HH:mm"),
                    email,
                    phoneNum,
                    info,
                    price
                })
            });

            if (response.ok) {
                notifications.show({
                    color: "teal",
                    title: "Objednávka byla úspěšná!",
                    autoClose: 5000,
                    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                    message: `Ozveme se Vám, hned jak to bude možné ;)`
                });
            } else {
                throw new Error("Chyba při odesílání oznamovacího mailu");
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            notifications.show({
                color: "red",
                title: "Něco se pokazilo",
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                message: "Vyskytla se chyba, zkuste to prosím později." + error.message
            });
        }
    }, [eventRef]);

    const contactUsForm = useCallback(async (name, email, subject, msg) => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API}emails/send-contactus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name,
                    email,
                    subject,
                    msg
                })
            });

            if (!response.ok) {
                notifications.show({
                    color: "red",
                    title: "Něco se pokazilo",
                    icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                    message: "Vyskytla se chyba, zkuste to prosím později." + response.message
                });
                setIsLoading(false);
                return;
            }

            notifications.show({
                color: "teal",
                title: "Zpráva byla úspěšně odeslána",
                autoClose: 5000,
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                message: `Ozveme se Vám, hned jak to bude možné ;)`
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            notifications.show({
                color: "red",
                title: "Něco se pokazilo",
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                message: "Vyskytla se chyba, zkuste to prosím později." + error.message
            });
        }
    }, []);

    const deleteEventById = useCallback(async (id) => {
        if (!isAdmin) return;
        const adminMethods = await import('./AdminMethods');
        return adminMethods.deleteEventById(id);
    }, [isAdmin]);

    const writeEventToDatabase = useCallback(async (title, description, startDatetime, endDatetime, type, place, unknowStart, unknowEnd) => {
        if (!isAdmin) return;
        const adminMethods = await import('./AdminMethods');
        return adminMethods.writeEventToDatabase(title, description, startDatetime, endDatetime, type, place, unknowStart, unknowEnd, user);
    }, [isAdmin, user]);

    const updateEvent = useCallback(async (id, data) => {
        if (!isAdmin) return;
        const adminMethods = await import('./AdminMethods');
        return adminMethods.updateEvent(id, data);
    }, [isAdmin]);

    const getEventById = useCallback(async (eventId) => {
        if (!isAdmin) return;
        const adminMethods = await import('./AdminMethods');
        return adminMethods.getEventById(eventId);
    }, [isAdmin]);

    const getOrders = useCallback(async () => {
        if (!isAdmin) return;
        const adminMethods = await import('./AdminMethods');
        return adminMethods.getOrders();
    }, [isAdmin]);

    const value = useMemo(() => ({
        isLoading,
        getEventsForMonth,
        getClosestEvent,
        deleteEventById,
        writeEventToDatabase,
        updateEvent,
        createOrderEvent,
        contactUsForm,
        getEventById,
        getOrders
    }), [
        isLoading,
        getEventsForMonth,
        getClosestEvent,
        deleteEventById,
        writeEventToDatabase,
        updateEvent,
        createOrderEvent,
        contactUsForm,
        getEventById,
        getOrders
    ]);

    return (
        <DatabaseContext.Provider value={value}>
            {children}
        </DatabaseContext.Provider>
    );
}
