import { Button, Divider, Grid, LoadingOverlay, NumberFormatter, Paper, Text, TextInput, Textarea, Title } from "@mantine/core";
import { DEFAULT_NUMBER_OF_CARS, HOURLY_RATE, NUMBER_OF_US, ORIGIN, PRICE_PER_KM } from "../constants/us";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDebouncedValue, useDebouncedCallback, useDocumentTitle } from "@mantine/hooks";
import { useState, useEffect } from "react";
import { differenceInMinutes, format } from "date-fns";
import { API } from "../constants/API";
import { useDatabse } from "../contexts/DatabaseContex";

export default function PlaceOrder() {

    const {createOrderEvent, isLoading} = useDatabse();

    useDocumentTitle('Cimbálová Muzika - Kuráž | Objednávka');

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            place: '',
            info: '',
            startDatetime: '',
            distance: '',
            endDatetime: '',
            phoneNum: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Neplatný email')
        }
    })

    const [debouncedPlace] = useDebouncedValue(form.values.place, 400);
    const [hourDiff, setHourDiff] = useState(0);
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');

    const handleCalculateDistance = async () => {
        const url = `${API}distance?origin=${encodeURIComponent(ORIGIN)}&destination=${encodeURIComponent(form.values.place)}`;
    
        try {
            const response = await fetch(url);
            const data = await response.json();
    
            if (data.rows && data.rows[0].elements[0].status === 'OK') {
                const distanceInMeters = data.rows[0].elements[0].distance.value;
                return Math.round(distanceInMeters / 100) / 10;
            } else {
                console.log("Unable to find the distance due to an error");
            }
        } catch (err) {
            console.log('Failed to fetch distance');
            console.error('Error fetching data:', err);
        }
    };

    const handleSearch = useDebouncedCallback(async () => {
        form.setFieldValue("distance", await handleCalculateDistance());
      }, 400);

      const calculateHourDiff = (start, end) => {
        const minutesDiff = differenceInMinutes(new Date(end), new Date(start));
        return (minutesDiff / 60).toFixed(1);  // Convert to hours and round to one decimal place
    };

      useEffect(() => {
        if (startDatetime && endDatetime) {
            const hours = calculateHourDiff(startDatetime, endDatetime);
            setHourDiff(hours);
        }
    }, [startDatetime, endDatetime]); // Dependencies array

    const handleOrder = async () => {
        try {
            await createOrderEvent(
                '',
                form.values.startDatetime,
                form.values.endDatetime,
                form.values.place,
                form.values.name,
                form.values.email,
                form.values.info,
                form.values.phoneNum,
                (form.values.distance * PRICE_PER_KM * DEFAULT_NUMBER_OF_CARS) + (hourDiff * HOURLY_RATE * NUMBER_OF_US))
        } catch (e) {
            return;
        }
    }


    return (
        <Paper shadow='xs' p="xs" withBorder className='mt-5'>
            <form onSubmit={form.onSubmit(handleOrder)} className="relative">
            <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <Grid className="mt-3">
                <Grid.Col span={{ base: 12, md: 8 }}>
                    <Title order={5}>Objednávka CM - Kuráž</Title>
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput
                                required
                                label="Vaše jméno"
                                placeholder="Jan Novák"
                                value={form.values.name}
                                onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                required
                                label="Váš Email"
                                placeholder="j.novak@cm-kuraz.cz"
                                value={form.values.email}
                                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                required
                                label="Váš Telefon"
                                placeholder="+420 123 456 789"
                                value={form.values.phoneNum}
                                onChange={(event) => form.setFieldValue('phoneNum', event.currentTarget.value)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                required
                                label="Kam máme zavítat?"
                                value={form.values.place} 
                                onChange={(event) => {form.setFieldValue('place', event.currentTarget.value); handleSearch()}}
                                placeholder="Liptál"
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <DateTimePicker
                                required
                                type="range"
                                defaultValue={new Date()}
                                onChange={(event) => {
                                    const formattedEnd = format(event, "yyyy-MM-dd'T'HH:mm");
                                    form.setFieldValue('startDatetime', formattedEnd);
                                    setStartDatetime(formattedEnd);
                                }}
                                valueFormat="D. M. YYYY HH:mm"
                                label="Kdy?"
                                placeholder=""
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <DateTimePicker
                                required
                                defaultValue={new Date()}
                                onChange={(event) => {
                                    const formattedEnd = format(event, "yyyy-MM-dd'T'HH:mm");
                                    form.setFieldValue('endDatetime', formattedEnd);
                                    setEndDatetime(formattedEnd);
                                }}
                                valueFormat="D. M. YYYY HH:mm"
                                label="Do kdy?"
                                placeholder=""
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <Textarea
                                label="Poznámka"
                                placeholder=""
                                autosize
                                value={form.values.info} 
                                onChange={(event) => form.setFieldValue('info', event.currentTarget.value)}
                                minRows={2}
                            />
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
                <Divider className="hidden md:block" orientation="vertical" />
                <Grid.Col span="auto">
                    <Title order={5}>Sumář</Title>
                    <Grid>
                        <Grid.Col span={6}>
                            <Text>My:</Text>
                            <Text size="xs" c="dimmed">{hourDiff > 0 ? `Počet hodin: ${hourDiff}h` : ""}</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            {hourDiff * HOURLY_RATE * NUMBER_OF_US >= 0 ? (
                                <NumberFormatter thousandSeparator=" " value={hourDiff * HOURLY_RATE * NUMBER_OF_US} decimalScale={2} fixedDecimalScale decimalSeparator="," suffix=" Kč" />
                            ) : (
                                <Text c="red">Neplatný datum nebo čas</Text>
                            )
                            }
                        </Grid.Col>

                        <Grid.Col span={6}>
                            <Text>Cesta:</Text>
                            <Text size="xs" c="dimmed">Liptál - {debouncedPlace} {form.values.distance ? `(${form.values.distance} km)` : ""}</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <NumberFormatter thousandSeparator=" " value={form.values.distance * PRICE_PER_KM * DEFAULT_NUMBER_OF_CARS} decimalScale={2} fixedDecimalScale decimalSeparator="," suffix=" Kč" />
                        </Grid.Col>
                    </Grid>
                    <Divider my={8}/>
                        <Grid>
                            <Grid.Col span={6}>
                                <Title order={6}>Dohromady:</Title>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                {(form.values.distance * PRICE_PER_KM * DEFAULT_NUMBER_OF_CARS) + (hourDiff * HOURLY_RATE * NUMBER_OF_US) >= 0 ? (
                                    <NumberFormatter thousandSeparator=" " value={(form.values.distance * PRICE_PER_KM * DEFAULT_NUMBER_OF_CARS) + (hourDiff * HOURLY_RATE * NUMBER_OF_US)} decimalScale={2} fixedDecimalScale decimalSeparator="," suffix=" Kč" />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid.Col>
                        </Grid>
                            <Text size="xs" c="dimmed">* Výsledná cena se může změnit</Text>
                            <Button type="submit" fullWidth className="mt-5">Objednat</Button>

                </Grid.Col>
            </Grid>
            </form>
        </Paper>
    )
}