export const isEventPrivate = (eventData, isAdmin) => {
    if (eventData.type === 0 || (eventData.type === 1 && isAdmin) || (eventData.type === 2 && isAdmin)) {
        return false;
    }
    if (eventData.type === 1 && !isAdmin) {
        return true;
    }
    
    // Default case if none of the above conditions are met
    return false;
}