import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import PlaceOrder from '../components/PlaceOrder';
import { useAuth } from '../contexts/AuthContext';
import { LoadingOverlay } from '@mantine/core';
import { NoteProvider } from '../contexts/NoteContext';

const NotFoundImage = lazy(() => import('../components/notFound/NotFound'));
const Orders = lazy(() => import('../pages/Orders'));
const Tutorial = lazy(() => import('../pages/Tutorial'));
const LoginPage = lazy(() => import('../pages/Account/Login'));
const RegisterPage = lazy(() => import('../pages/Account/Register'));
const NotesPage = lazy(() => import('../pages/Notes'));

function RouteHandler() {
  const { user, isAdmin } = useAuth();

  const GuestOnlyRoute = ({ children }) => {
    return !user ? children : <Navigate to="/" replace />;
  };

  const AdminOnlyRoute = ({ children }) => {
    return isAdmin ? children : <Navigate to="/" replace />;
  };

  return (
    <Suspense fallback={<LoadingOverlay visible={true} />}>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account/login" element={<GuestOnlyRoute><LoginPage /></GuestOnlyRoute>} />
          <Route path="/account/register" element={<GuestOnlyRoute><RegisterPage /></GuestOnlyRoute>} />
          <Route path="/orders" element={<AdminOnlyRoute><Orders /></AdminOnlyRoute>} />
          <Route path="/order" element={<PlaceOrder />} />
          <Route path='/notes' element={<NoteProvider><NotesPage /></NoteProvider>} />
          <Route path='/notes/*' element={<NoteProvider><NotesPage /></NoteProvider>} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route path="*" element={<NotFoundImage />} />
      </Routes>
    </Suspense>
  );
}

export default RouteHandler;
