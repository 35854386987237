import { Button, Checkbox, Grid, Group, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { format } from "date-fns";
import { useDatabse } from "../contexts/DatabaseContex";
import { useAuth } from "../contexts/AuthContext";
import { Timestamp } from "firebase/firestore";
import 'dayjs/locale/cs';

export default function EditEventModal({context,id, innerProps: eventData}) {
    //console.log(eventData);
    const {isLoading, updateEvent} = useDatabse();
    const {user} = useAuth();
    const form = useForm({
        initialValues: {
            name: eventData.eventData?.title ?? '',
            place: eventData.eventData?.place ?? '',
            description: eventData.eventData?.description ?? '',
            startDatetime: eventData.eventData.title ? eventData.eventData.startDatetime.toDate() : '',
            endDatetime: eventData.eventData.title ? eventData.eventData.startDatetime.toDate() : '',
            unknowStart: eventData.eventData?.unknowStart ?? false,
            unknowEnd: eventData.eventData?.unknowEnd ?? false,
            eventType: eventData.eventData?.type ?? "0",
            confirmed: eventData.eventData.attendees[user.uid].confirmed
        },

        validate: {
            name: (value) => value !== '' ? null : "Toto pole nemůže být prázdné",
            place: (value) => value !== '' ? null : "Toto pole nemůže být prázdné",

        }
    });

    const handleEventUpdate = async () => {
        const updatedValues = {
            title: form.values.name,
            description: form.values.description,
            startDatetime: Timestamp.fromDate(new Date(form.values.startDatetime)),
            endDatetime: Timestamp.fromDate(new Date(form.values.endDatetime)),
            unknowStart: form.values.unknowStart,
            unknowEnd: form.values.unknowEnd,
            type: parseInt(form.values.eventType),
            place: form.values.place,
            editedBy: user.email,
            editedAt: Timestamp.fromDate(new Date()),
        };
        // Directly target only the 'confirmed' status of this specific user
        updatedValues[`attendees.${user.uid}.confirmed`] = parseInt(form.values.confirmed);
        
        try {
            await updateEvent(eventData.eventData.id, updatedValues);
        } catch (e) {
            return
        }
    }

    return (
        <>
        <form onSubmit={form.onSubmit(handleEventUpdate)} className="relative">
                <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                <Grid>
                    <Grid.Col span={{sm: 12, md: 6}}>
                        <TextInput
                            required
                            label="Název"
                            placeholder="Název"
                            value={form.values.name} 
                            onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                        />
                    </Grid.Col>
                    <Grid.Col span={{sm: 12, md: 6}}>
                        <TextInput
                            required
                            label="Místo"
                            placeholder="Místo"
                            value={form.values.place} 
                            onChange={(event) => form.setFieldValue('place', event.currentTarget.value)}
                        />
                    </Grid.Col>
                    <Grid.Col span={{sm: 12, md: 6}} className="mb-3">
                        <DateTimePicker
                            required
                            valueFormat="D. M. YYYY HH:mm"
                            defaultValue={eventData.eventData.startDatetime.toDate()}
                            label="Začátek"
                            placeholder="Začátek"
                            //onChange={(e) => console.log(e)}
                            //value={form.values.startDatetime}
                            onChange={(event) => form.setFieldValue('startDatetime', format(event, "yyyy-MM-dd'T'HH:mm"))}
                        />
                        <Checkbox checked={form.values.unknowStart} onChange={(event) => form.setFieldValue('unknowStart', event.currentTarget.checked)} label="Neznámý čas?" />
                    </Grid.Col>
                    <Grid.Col span={{sm: 12, md: 6}}>
                        <div>
                            <DateTimePicker
                                valueFormat="D. M. YYYY HH:mm"
                                defaultValue={eventData.eventData.endDatetime.toDate()}
                                label="Konec"
                                placeholder="Konec"
                                //onChange={(e) => console.log(e)}
                                //value={form.values.startDatetime}
                                onChange={(event) => form.setFieldValue('endDatetime', format(event, "yyyy-MM-dd'T'HH:mm"))}
                            />
                            <Checkbox checked={form.values.unknowEnd} onChange={(event) => form.setFieldValue('unknowEnd', event.currentTarget.checked)} label="Neznámý čas?" />
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            required
                            className="mb-3"
                            label="Typ akce"
                            placeholder="Vyber"
                            defaultValue={`${eventData.eventData.type}`}
                            onChange={(_value, option) => form.setFieldValue("eventType", option.value)}
                            data={[{value: "0", label: 'Veřejná akce'}, {value: "1", label:'Soukromá akce'}, {value: "2", label:'Otazníková akce'}]}
                        />
                    </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                className="mb-3"
                                label="Tvoje Účast"
                                placeholder="Vyber"
                                defaultValue={`${eventData.eventData.attendees[user.uid].confirmed}`}
                                onChange={(_value, option) => form.setFieldValue("confirmed", option.value)}
                                data={[{value: "0", label: 'Čeká se'}, {value: "1", label:'Potvrzeno'}, {value: "2", label:'Nepotvrzeno'}]}
                            />
                        </Grid.Col>
                </Grid>

                <Group justify="space-between">
                    <Button variant="outline" color="red" onClick={() => context.closeModal(id)}>
                    Zrušit
                    </Button>
                    <Button type="submit">Upravit</Button>

                </Group>
                
            </form>
        </>
    )
}