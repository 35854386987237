import { ActionIcon, Badge, Divider, Grid, Group, rem, Menu, Modal, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconClock, IconDotsVertical, IconInfoCircle, IconMapPin, IconTrash, IconPencil } from "@tabler/icons-react";
import { format } from "date-fns";
import { useAuth } from "../contexts/AuthContext";
import { isEventPrivate } from "../helpers/isEventPrivate";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";

export default function EventInfo(eventData) { // haha pičovina krva drát
    eventData = eventData.eventData;
    const {user, isAdmin} = useAuth();

    const [opened, { open, close }] = useDisclosure();
    
    return (
        <>
        <Modal opened={opened} onClose={close} title={eventData.title}>
            <Grid>
                <Grid.Col span={6}>
                    <Text fw={500}>Místo</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{eventData.place}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Začátek</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{!eventData.unknowStart ? format(eventData.startDatetime.toDate(), "dd. M. yyyy HH:mm") : format(eventData.startDatetime.toDate(), "dd. M. yyyy") + " - Neznámy"}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Konec</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{!eventData.unknowEnd ? format(eventData.endDatetime.toDate(), "dd. M. yyyy HH:mm") : format(eventData.endDatetime.toDate(), "dd. M. yyyy") + " - Neznámy"}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                    <Text fw={500}>Typ akce</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>{eventData.type === 0 ? "Veřejná akce" : eventData.type === 1 ? "Soukromá akce" : "Otazníková akce"}</Text>
                </Grid.Col>
                <>    
                <Grid.Col span={12}>
                    <Divider />
                    <Text fw={500}>Hvězdy večera</Text>
                </Grid.Col>

                {Object.keys(eventData.attendees).map((key, index) => (
                    <>
                    <Grid.Col key={index} span={6}>
                        <Text>{eventData.attendees[key].name}</Text>
                    </Grid.Col>

                    <Grid.Col key={index + "1"} span={6}>
                        
                            <Badge color={eventData.attendees[key].confirmed === 0 ? "blue" : eventData.attendees[key].confirmed === 1 ? "green" : "red"}>
                                {eventData.attendees[key].confirmed === 0 ? "Čeká se.." : eventData.attendees[key].confirmed === 1 ? "Potvrzeno" : "Nepotvrzeno"}
                            </Badge>
                    </Grid.Col>
                    </>
                ))}
                </>
            </Grid>
        </Modal>
            <Group justify="space-between" className="p-1.5 cursor-pointer hover:bg-gray-100 rounded-md">
        <div className=" flex flex-col" >

            <div className="inline-flex gap-2 items-center start-0">
                <Text c="black">{!isEventPrivate(eventData, isAdmin) ? eventData.title : "Soukromá akce"}</Text>
                {isAdmin && (
                    <>
                    {eventData.type === 2 && (
                        <Tooltip label="Otazníková akce" withArrow>
                            <Badge color="orange" circle >?</Badge>
                        </Tooltip>
                    )}
                        <Tooltip withArrow label={eventData.attendees[user.uid].confirmed === 0 ? "Čeká se.." : eventData.attendees[user.uid].confirmed === 1 ? "Potvrzeno" : "Nepotvrzeno"}>
                            <Badge color={eventData.attendees[user.uid].confirmed === 0 ? "blue" : eventData.attendees[user.uid].confirmed === 1 ? "green" : "red"} circle>
                                {eventData.attendees[user.uid].confirmed === 1 ? (
                                    <IconCheck className="w-3 h-3" />
                                    ) : 
                                    "X"}
                            </Badge>
                        </Tooltip>
                    </>
                )}
            </div>

            <div className="inline-flex items-center gap-1">
                <IconClock className="w-4 h-4" />
                <Text c="dimmed" size="sm">{!eventData.unknowStart ? format(eventData.startDatetime.toDate(), "HH:mm") : "Neznámý"} - {!eventData.unknowEnd ? format(eventData.endDatetime.toDate(), "HH:mm") : "Neznámý"}</Text>
            </div>
            <div className="inline-flex items-center gap-1">
                <IconMapPin className="w-4 h-4" />
                <Text c="dimmed" size="sm">{!isEventPrivate(eventData, isAdmin) ? eventData.place : "-"}</Text>
            </div>
                </div>
            {isAdmin && (
                <Menu shadow="sm" width={200} position="bottom-end">
                    <Menu.Target>
                        <ActionIcon variant="default" aria-label="nastavení">
                            <IconDotsVertical style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item onClick={open} leftSection={<IconInfoCircle style={{ width: rem(14), height: rem(14) }} />}>
                            Zobrazit
                        </Menu.Item>
                        <Menu.Item onClick={() =>
                            modals.openContextModal({
                            modal: 'edit',
                            title: 'Upravit akci',
                            innerProps: {
                                eventData
                            }
                            })} 
                            leftSection={<IconPencil style={{ width: rem(14), height: rem(14) }} />}>
                            Upravit
                        </Menu.Item>
                        <Menu.Item onClick={() =>
                            modals.openContextModal({
                            modal: 'delete',
                            title: 'Smazat akci',
                            innerProps: {
                                eventData
                            }
                            })} className="hover:bg-red-400/20" leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}>
                            Smazat
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            )}

            </Group>
        </>
    )
}