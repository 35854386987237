import React, { useState, useContext, useEffect } from "react"
import { auth } from "../firebase-config"
import {createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signOut,
    AuthErrorCodes} from "firebase/auth"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)

    // listens to user auth interaction
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (process.env.REACT_APP_ADMINS.includes(user?.uid)) {
                setIsAdmin(true)
            }
            setUser(user)
        })

        return unsubscribe 
    }, [])

    // custom error messages
    const errorMesseges = { 
        [AuthErrorCodes.EMAIL_EXISTS]: 'E-mail je již používán, zkuste se přihlásit, nebo resetovat heslo',
        [AuthErrorCodes.INVALID_EMAIL]: 'Neplatná emailová adresa'
    }

    async function signUp(email, password) {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          if (!userCredential.user.emailVerified) { // check if user has verified email, if not send verification email
            sendEmailVerification(userCredential)
          }
        } catch (error) {
            console.log(error)
            return errorMesseges[error.code] || 'Při přihlašování se vyskytla neznámá chyba'
            //throw new Error(errorMessage)
        }
      }

    function signIn(email,password) {
        //firebase login with email and passwd
        return signInWithEmailAndPassword(auth, email, password);
    }

    async function passwordReset(email) {
        try {
          await sendPasswordResetEmail(auth, email);
          return true;
        } catch (error) {
          return error.message;
        }
      }

    function logOut() {
        //
        window.location.href ="/"
        return signOut(auth)
    }

    

    const value = {
        user,
        signIn,
        logOut,
        signUp,
        auth,
        passwordReset,
        isAdmin
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}