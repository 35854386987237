import React, { lazy, useEffect, useState, useMemo, useCallback } from 'react';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
} from 'date-fns';
import { MdMusicNote } from "react-icons/md";
import { useDatabse } from '../contexts/DatabaseContex';
import { useAuth } from '../contexts/AuthContext';
import { cs } from 'date-fns/locale';
import EventInfo from './EventInfo';

const AddNewEvent = lazy(() => import('./AddNewEvent'));

export function Calendar() {
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(startOfToday());
  const [currentMonth, setCurrentMonth] = useState(format(startOfToday(), 'MMMM-yyyy'));
  const firstDayCurrentMonth = parse(currentMonth, 'MMMM-yyyy', new Date());
  const days =eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  const { getEventsForMonth } = useDatabse();
  const { isAdmin } = useAuth();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await getEventsForMonth(currentMonth);
        setEvents(fetchedEvents);
        console.log('Fetched events:', fetchedEvents);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };
    fetchEvents();
  }, [currentMonth, getEventsForMonth]);

  const selectedDayMeetings = useMemo(() => {
    if (events && events.length > 0) {
      return events.filter(
        (meeting) =>
          meeting.startDatetime && isSameDay(meeting.startDatetime.toDate(), selectedDay)
      );
    }
    return [];
  }, [events, selectedDay]);

  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  const previousMonth = useCallback(() => {
    const firstDayPrevMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayPrevMonth, 'MMMM-yyyy'));
  }, [firstDayCurrentMonth]);

  const nextMonth = useCallback(() => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMMM-yyyy'));
  }, [firstDayCurrentMonth]);

  const EventIndicator = useMemo(() => ({ events, day }) => {
    const getRelevantEvent = () => {
      const eventsToday = events.filter(meeting =>
        isSameDay(meeting.startDatetime.toDate(), day)
      );
      return eventsToday[0];
    };

    const relevantEvent = getRelevantEvent();
    if (relevantEvent) {
      const eventType = relevantEvent.type;
      return (
        <div className="w-1 h-1 mt-[-30px] ml-2">
          <MdMusicNote size={12}
            className={classNames(
              isEqual(day, selectedDay) ? 'text-white' :
                eventType !== 2 ? 'text-blue-700' : 'text-[#D9480F]'
            )}
          />
        </div>
      );
    }
    return null;
  }, [events, selectedDay]);

  const handleClick = (event, day) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedDay(day);
  };

  return (
    <>
      <div className="md:pr-14" id="calendar">
        <div className="flex items-center">
          <h2 className="flex-auto font-semibold text-gray-900">
            {format(firstDayCurrentMonth, 'MMM yyyy', { locale: cs })}
          </h2>
          <button
            type="button"
            onClick={previousMonth}
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
          >
            <span className="sr-only">Předchozí měsíc</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button
            type="button"
            onClick={() => {
              setSelectedDay(startOfToday());
              setCurrentMonth(format(startOfToday(), 'MMMM-yyyy'));
            }}
            className="-my-1.5 flex flex-none text-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
          >
            Dnes
          </button>
          <button
            onClick={nextMonth}
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
          >
            <span className="sr-only">Další měsíc</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
        <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
          <div>Po</div>
          <div>Út</div>
          <div>St</div>
          <div>Čt</div>
          <div>Pá</div>
          <div>So</div>
          <div>Ne</div>
        </div>
        <div className="grid grid-cols-7 mt-2 text-sm">
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)],
                'py-1.5'
              )}
            >
              <button
                type="button"
                onClick={(event) => handleClick(event, day)}
                className={classNames(
                  isEqual(day, selectedDay) && 'text-white',
                  !isEqual(day, selectedDay) &&
                  isToday(day) &&
                  'text-red-500',
                  !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  isSameMonth(day, firstDayCurrentMonth) &&
                  'text-gray-900',
                  !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  !isSameMonth(day, firstDayCurrentMonth) &&
                  'text-gray-400',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',
                  isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  'bg-gray-900',
                  !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                  (isEqual(day, selectedDay) || isToday(day)) &&
                  'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                )}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
              </button>
              <div className="w-1 h-1 mx-auto mt-0.5">
                {events && events.length > 0 &&
                  <EventIndicator events={events} day={day} />
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="mt-12 md:mt-0 md:pl-14 relative overflow-x-auto max-h-96">
        <h2 className="font-semibold text-gray-900">
          Akce pro: {' '}
          <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>
            {format(selectedDay, 'd. M. yyyy')}
          </time>
        </h2>
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500 space-x-reverse">
          {selectedDayMeetings && selectedDayMeetings.length > 0 ? (
            selectedDayMeetings.map((meeting) => (
              <div key={meeting.id}>
                <EventInfo eventData={meeting} />
              </div>

            ))
          ) : (
            <>
              <p>Pro tento den nejsou žádné akce</p>
            </>
          )}
        </ol>
        {isAdmin && (
          <AddNewEvent selectedDatetime={selectedDay} />
        )}
      </section>
    </>
  );
}

const colStartClasses = [
  'col-start-7',
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
];
