import React, { useContext, useState } from "react";
import { storage } from "../firebase-config";
import { listAll, ref } from "firebase/storage";

const NoteContext = React.createContext();

export function useNotes() {
    return useContext(NoteContext);
}

export function NoteProvider({ children }) {
    const noteRef = ref(storage, "notes");
    const [isLoading, setIsLoading] = useState(false);

    async function listAllGenres() {
        try {
            setIsLoading(true);
            const response = await listAll(noteRef);
            // Extract folder names
            const genres = response.prefixes.map(folderRef => ({ name: folderRef.name, fullPath: folderRef.fullPath }));
            return genres;
        } catch (e) {
            console.log(e);
            return [];
        } finally {
            setIsLoading(false);
        }
    }
    
    const value = {
        isLoading,
        listAllGenres
    }

    return (
        <NoteContext.Provider value={value}>
            {children}
        </NoteContext.Provider>
    )
}