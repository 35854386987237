import React, { memo } from "react";
import { AppShell, Container } from "@mantine/core";
import Navbar from "./components/Navbar";
import RouteHandler from "./helpers/RouteHandler";
import Footer from "./components/Footer";

const MemoizedNavbar = memo(Navbar);
const MemoizedFooter = memo(Footer);

function App() {
  return (
    <AppShell header={{ height: { base: 48, sm: 60} }}>
      <AppShell.Header>
        <MemoizedNavbar />
      </AppShell.Header>
      <AppShell.Main>
        <Container size="xl">
          <RouteHandler />
          <MemoizedFooter />
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}

export default App;
