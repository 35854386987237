import { Button, Group, LoadingOverlay, Text } from "@mantine/core";
import { useDatabse } from "../contexts/DatabaseContex";

export default function DeleteEventModal({context,id, innerProps: eventData}) {
    //console.log(eventData);
    const {isLoading, deleteEventById} = useDatabse();

    const handleEventDelete = async () => {
        try {
            await deleteEventById(eventData.eventData.id);
        } catch (e) {
            return
        }
    }

    return (
        <>
        <div className="relative">
                <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                <Text>Opravdu chceš smazat akci {eventData.eventData.title}?</Text>

                <Group justify="space-between" className="mt-5">
                    <Button variant="outline" onClick={() => context.closeModal(id)}>
                    Zrušit
                    </Button>
                    <Button color="red" type="submit" onClick={() => {handleEventDelete(); context.closeModal(id)}}>Ano, smazat</Button>

                </Group>
        </div>
        </>
    )
}