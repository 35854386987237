import { Container, Group, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

const links = [
    { link: '/#', label: 'O nás' },
    { link: '/#', label: 'Kalendář' },
    { link: '/#', label: 'Kontakt' },
    { link: '/order', label: 'Objednat' },
  ];

export default function Footer() {
    const items = links.map((link) => (
        <Link
          key={link.label}
          to={link.link}
        >
            <Text className='hover:text-blue-400 text-muted hover:underline' size="sm">{link.label}</Text>
        </Link>
      ));

    return (
        <div className="mt-5 border-t-2 border-gray-200">
        <Container className="flex justify-between items-center pt-10 pb-10 flex-col md:flex-row">
            <div>CM - Kuráž</div>
            <Group className="mt-5 md:mt-0">{items}</Group>
        </Container>
        </div>
    )
}