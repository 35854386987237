import { Text, Box, Stack, rem, TextInput, Textarea, Button, Group, SimpleGrid, LoadingOverlay } from '@mantine/core';
import { IconPhone, IconAt, IconBrandInstagram } from '@tabler/icons-react';
import BgSVG from './bg.svg';
import classes from './GetInTouch.module.css';
import classesi from './ContactIcons.module.css';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { useDatabse } from '../contexts/DatabaseContex';


function ContactIcon({ icon: Icon, title, description, ...others }) {
  return (
    <div className={classesi.wrapper} {...others}>
      <Box mr="md">
        <Icon style={{ width: rem(24), height: rem(24) }} />
      </Box>

      <div>
        <Text size="xs" className={classesi.title}>
          {title}
        </Text>
        <Text className={classesi.description}>{description}</Text>
      </div>
    </div>
  );
}

const MOCKDATA = [
  { title: 'Email', description: 'vergoj@seznam.cz', icon: IconAt },
  { title: 'Telefon', description: '+420 774 256 324', icon: IconPhone },
  { title: 'Instagram', description: '@cm_kuraz', icon: IconBrandInstagram },
];

function ContactIconsList() {
  const items = MOCKDATA.map((item, index) => <ContactIcon key={index} {...item} />);
  return <Stack>{items}</Stack>;
}

export default function ContactUs() {
  const formContactUs = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      msg: ""
    },
    validate: {
      email: isEmail('Neplatný email'),
      subject: isNotEmpty('Prosím vyplňte předmět'),
      msg: isNotEmpty('Prosím vyplňte zprávu'),
      name: isNotEmpty('Napište prosím své jméno'),
    }
  })

  const {contactUsForm, isLoading} = useDatabse();

  const handleFormSubmit = () => {
    //event.preventDefault();
    contactUsForm(formContactUs.values.name, formContactUs.values.email, formContactUs.values.subject, formContactUs.values.msg)
  }

  return(
    
    <div className={classes.wrapper}>
    <div className={classes.contacts} style={{ backgroundImage: `url(${BgSVG})` }}>
      <Text fz="lg" fw={700} className={classes.title} c="#fff">
        Kontaktní Informace
      </Text>

      <ContactIconsList />
    </div>

    <div className={classes.form}>
    <div className='relative'>
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <Text fz="lg" fw={700} className={classes.title}>
        Napište Nám!
      </Text>

      <div className={classes.fields}>
        <form onSubmit={formContactUs.onSubmit(handleFormSubmit)}>
        <SimpleGrid cols={{ base: 1, sm: 2 }}>
          <TextInput {...formContactUs.getInputProps('name')} value={formContactUs.values.name} onChange={(event) => formContactUs.setFieldValue('name', event.currentTarget.value)} label="Vaše jméno" placeholder="Jan Novák"/>
          <TextInput {...formContactUs.getInputProps('email')} value={formContactUs.values.email} onChange={(event) => formContactUs.setFieldValue('email', event.currentTarget.value)} label="Váš email" placeholder="j.novak@cm-kuraz.cz" />
        </SimpleGrid>

        <TextInput {...formContactUs.getInputProps('subject')} value={formContactUs.values.subject} onChange={(event) => formContactUs.setFieldValue('subject', event.currentTarget.value)} mt="md" label="Předmět" placeholder="Předmět" />

        <Textarea
          {...formContactUs.getInputProps('msg')} value={formContactUs.values.msg} onChange={(event) => formContactUs.setFieldValue('msg', event.currentTarget.value)}
          mt="md"
          label="Vaše zpráva"
          placeholder="Vaše zpráva"
          minRows={3}
          />

        <Group justify="flex-end" mt="md">
          <Button type='submit' className={classes.control}>
            Poslat
          </Button>
        </Group>
        </form>
      </div>
          </div>
    </div>
  </div>
  )
}