export const AVATAR_API = "https://ui-avatars.com/api";
export const API = "https://cm-kuraz-backend-47e081eaa810.herokuapp.com/";
//export const API = "http://localhost:3001/";

// barvy pro eventy
// 0 - veřejná akce
// 1 - soukromá akce
// 2 - otazníková akce
export const EVENT_COLORS = ["#1C7ED6", "#1C7ED6", "#D9480F"];

// nic víc tu není puso ;)
