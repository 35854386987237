import { Title, Badge, Text, Tooltip, Group } from "@mantine/core";
import { IconCheck, IconClock, IconMapPin } from "@tabler/icons-react";
import { format } from "date-fns";
import { useAuth } from "../contexts/AuthContext";
import { useState, useEffect } from "react";
import { useDatabse } from "../contexts/DatabaseContex";
import { isEventPrivate } from "../helpers/isEventPrivate";
import { getDayDifference } from "../helpers/getDayDifference";

export default function ClosestEvent() {
    const { getClosestEvent } = useDatabse();
    const [closestEvent, setClosestEvent] = useState([]);

    const { user, isAdmin } = useAuth();

    useEffect(() => {
        getClosestEvent().then(fetchedEvents => {
            setClosestEvent(fetchedEvents);
            console.log(fetchedEvents);
        }).catch(error => {
            console.error('Failed to fetch events:', error);
        });
    }, []);


    return (
        <>
            {/* 
                <Group justify="space-between">

                <Title order={4}>Nejbližší akce</Title>
                </Group>
                {closestEvent ? (
                    <div className="p-1.5 cursor-pointer hover:bg-gray-100 rounded-md flex flex-col">
                    <div className="inline-flex gap-2 items-center start-0">
                        <Group justify="space-between">
                            <Text c="black">{!isEventPrivate(closestEvent, isAdmin) ? closestEvent.title : "Soukromá akce"}</Text>
                            {closestEvent && <Badge>{getDayDifference(closestEvent.startDatetime)}</Badge>}
                        </Group>
                    {isAdmin && (
                        <>
                        {closestEvent.type === 2 && (
                            <Tooltip label="Otazníková akce" withArrow>
                                <Badge color="orange" circle >?</Badge>
                            </Tooltip>
                        )}
                            <Tooltip withArrow label={closestEvent.attendees[user.uid].confirmed === 0 ? "Čeká se.." : closestEvent.attendees[user.uid].confirmed === 1 ? "Potvrzeno" : "Nepotvrzeno"}>
                                <Badge color={closestEvent.attendees[user.uid].confirmed === 0 ? "blue" : closestEvent.attendees[user.uid].confirmed === 1 ? "green" : "red"} circle>
                                    {closestEvent.attendees[user.uid].confirmed === 1 ? (
                                        <IconCheck className="w-3 h-3" />
                                        ) : 
                                        "X"}
                                </Badge>
                            </Tooltip>
                        </>
                    )}
                </div>

                    <div className="inline-flex items-center gap-1">
                        <IconClock className="w-4 h-4" />
                        <Text c="dimmed" size="sm">{!closestEvent.unknowStart ? format(closestEvent.startDatetime.toDate(), "HH:mm") : "Neznámý"} - {!closestEvent.unknowEnd ? format(closestEvent.endDatetime.toDate(), "HH:mm") : "Neznámý"}</Text>
                    </div>
                    <div className="inline-flex items-center gap-1">
                        <IconMapPin className="w-4 h-4" />
                        <Text c="dimmed" size="sm">{!isEventPrivate(closestEvent, isAdmin) ? closestEvent.place : "-"}</Text>
                    </div>
                </div>
                ) : (
                    <Text>Žádná nejbližší akce</Text>
                )}
        */}
            <Title order={4}>Nejbližší akce</Title>

            {closestEvent.length > 0 ? closestEvent.map((event, id) => (
                <div className="mt-2 hover:bg-gray-100 rounded-sm p-2">

                    <Group justify="space-between" key={id}>
                        <Group>
                            <Text c="black">{!isEventPrivate(event, isAdmin) ? event.title : "Soukromá akce"}</Text>
                            {isAdmin && (
                                <>
                                    {event.type === 2 && (
                                        <Tooltip label="Otazníková akce" withArrow>
                                            <Badge color="orange" circle >?</Badge>
                                        </Tooltip>
                                    )}
                                    <Tooltip withArrow label={event.attendees[user.uid].confirmed === 0 ? "Čeká se.." : event.attendees[user.uid].confirmed === 1 ? "Potvrzeno" : "Nepotvrzeno"}>
                                        <Badge color={event.attendees[user.uid].confirmed === 0 ? "blue" : event.attendees[user.uid].confirmed === 1 ? "green" : "red"} circle>
                                            {event.attendees[user.uid].confirmed === 1 ? (
                                                <IconCheck className="w-3 h-3" />
                                            ) :
                                                "X"}
                                        </Badge>
                                    </Tooltip>
                                </>
                            )}
                        </Group>
                        <Badge>{getDayDifference(event.startDatetime)}</Badge>
                    </Group>
                    <Group>
                        <IconClock className="w-4 h-4" />
                        <Text c="dimmed" size="sm">{!event.unknowStart ? format(event.startDatetime.toDate(), "HH:mm") : "Neznámý"} - {!event.unknowEnd ? format(event.endDatetime.toDate(), "HH:mm") : "Neznámý"}</Text>
                    </Group>
                    <Group>
                        <IconMapPin className="w-4 h-4" />
                        <Text c="dimmed" size="sm">{!isEventPrivate(event, isAdmin) ? event.place : "-"}</Text>
                    </Group>
                </div>
            )) : (
                <Text>Žádná nejbližší akce</Text>
            )}


        </>
    )
}