import {
  Group,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  Button,
  rem,
  NavLink,
  Avatar,
  Menu,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IconAward, IconCalendar, IconFileMusic, IconInfoCircle, IconLogout, IconSettings, IconUserCircle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { AVATAR_API } from '../constants/API';

const linksItems = [
  {
    name: "Onás",
    href: "/#about-us",
    icon: <IconInfoCircle size={"1rem"} stroke={1.5} />
  },
  {
    name: "Kalendář",
    href: "/#calendar",
    icon: <IconCalendar size={"1rem"} stroke={1.5} />
  },
  {
    name: "Kontakt",
    href: "/#contact",
    icon: <IconUserCircle size={"1rem"} stroke={1.5} />
  },
  {
    name: "Objednat",
    href: "/order",
    icon: <IconAward size={"1rem"} stroke={1.5} />
  }
]

export default function Navbar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const { user, logOut } = useAuth();
  //console.log(user)

  return (
    <Box className='items-center h-full text-center mx-5'>
        <Group justify="space-between" h="100%">
          <Link to="/">
           <div>CM - Kuráž</div>
          </Link>

          <Group h="100%" gap={0} visibleFrom="sm">
          <div className='inline-flex gap-2 mt-1.5'>
            {linksItems.map((link, index) => (
                <NavLink
                  component={Link}
                  to={link.href}
                  key={index}
                  className='h-full'
                  label={link.name}
                  leftSection={link.icon}

                  />
            ))}

            {/*isAdmin && (
                <NavLink
                  to="/notes"
                  className='h-full'
                  component={Link}
                  label="Noty"
                  leftSection={<IconFileMusic size={"1rem"} stroke={1.5} />}
                />
            ) */}
          </div>
          </Group>

          {user ? (
            <Group visibleFrom="sm">
              <Menu shadow='md' trigger='hover' width={200}>
                <Menu.Target>
                  <Avatar src={`${AVATAR_API}?name=${user?.email}&background=1890ff&color=fff`} />
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>{user.email}</Menu.Label>
                  <Link to="/account"><Menu.Item leftSection={<IconSettings style={{width: rem(14), height: rem(14)}} />}>Nastavení</Menu.Item></Link>
                  {/*isAdmin && <Link to="/orders"><Menu.Item leftSection={<IconAward style={{width: rem(14), height: rem(14)}} />}>Objednávky</Menu.Item></Link> */}
                  <Menu.Divider />
                  <Menu.Label>Nebezpečná zona</Menu.Label>
                  <Menu.Item
                    color="red"
                    onClick={() => logOut()}
                    leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                  >
                    Odhlásit se
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          ) : (
            <Group visibleFrom="sm">
              <Link to="/account/login">
                <Button variant="default">Přihlášení</Button>
              </Link>
              <Link to="/account/register">
                <Button>Registrace</Button>
              </Link>
            </Group>
          )}

          <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
        </Group>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="75%"
        padding="md"
        title="CM-Kuráž"
        hiddenFrom="sm"
        zIndex={1000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />
          {linksItems.map((link, index) => (
              <NavLink
                component={Link}
                to={link.href}
                key={index}
                label={link.name}
                leftSection={link.icon}
              />
            ))}

            {/*isAdmin && (
                <NavLink
                  component={Link}
                  label="Noty"
                  to="/notes"
                  leftSection={<IconFileMusic size={"1rem"} stroke={1.5} />}
                />
            )*/}

          <Divider my="sm" />
          {user ? (
          <>
            <div className='inline-flex items-center gap-2'>
              <Avatar size="sm" className='ml-3' src={`${AVATAR_API}?name=${user.email}&background=1890ff&color=fff`} />
              {user.email}
            </div>
            <Button onClick={() => logOut()} fullWidth color='red' className='mt-5 ml-3' >Odhlásit se</Button>
            </>
          ) : (
            <Group justify="center" grow pb="xl" px="md">
              <Link to="/account/login">
                <Button variant="default">Přihlášení</Button>
              </Link>
              <Link to="/account/register">
                <Button>Registrace</Button>
              </Link>
            </Group>
          )}
        </ScrollArea>
      </Drawer>
    </Box>
  );
}