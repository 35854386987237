import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@mantine/core/styles.css';
import { MantineProvider, LoadingOverlay } from '@mantine/core';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { DatabseProvider } from './contexts/DatabaseContex';
import { ModalsProvider  } from '@mantine/modals';
import 'dayjs/locale/cs';
import { DatesProvider } from '@mantine/dates';
import '@mantine/dates/styles.css';
import EditEventModal from './components/EditEventModal';
import DeleteEventModal from './components/DeleteEventModal';
import EventInfoModal from './components/ShowEventInfoModal';

/*
const DeleteEventModal = lazy(() => import('./components/DeleteEventModal'));
const EventInfoModal = lazy(() => import('./components/ShowEventInfoModal'));
*/
const RejectOrder = lazy(() => import('./components/RejectOrder'));

const modals = {
  edit: EditEventModal,
  delete: DeleteEventModal,
  eventInfo: EventInfoModal,
  rejectOrder: RejectOrder
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
            <MantineProvider forceColorScheme='light'>
    <Suspense fallback={<LoadingOverlay visible={true} />}>
      <AuthProvider>
        <DatabseProvider>
          <BrowserRouter>
              <ModalsProvider modals={modals}>
                <DatesProvider settings={{ locale: 'cs', firstDayOfWeek: 1, timezone: 'UTC' }}>
                  <Notifications />
                  <App />
                </DatesProvider>
              </ModalsProvider>
          </BrowserRouter>
        </DatabseProvider>
      </AuthProvider>
    </Suspense>
            </MantineProvider>
  </React.StrictMode>
);

