import { differenceInDays, startOfToday } from "date-fns";

export const getDayDifference = (startDatetime) => {
    //console.log(startDatetime);
    const today = startOfToday();
    const dayDiff = differenceInDays(startDatetime.toDate(), today);

    //console.log(dayDiff);

    switch (dayDiff) {
        case 0:
            return "Dnes"
        case 1:
            return "Zítra"
        case 2 || 3 || 4:
            return `Za ${dayDiff} dny`
        default:
            return `Za ${dayDiff} dní`
    }
}